import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LinkIcon from '@mui/icons-material/Link';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import colors from '../../theme/colors';
import ServiceDataContainer from '../../components/services/ServiceDataContainer';

const homeConsultationProps = {
    mainContainer: {
        padding: '55px 0px 41px',
    },
};

const serviceIntroductionData = {
    title: 'Dedicated Bandwidth',
    firstParagraph: 'Ancho de banda dedicado para todo tu VPC.',
    secondParagraph: 'Asegurá un enlace exclusivo para vos, que no compartirás con nadie más.',
    gatsbyImg: (<StaticImage src="../../images/dedicated_bandwidth_icon.svg" alt="Velocidad" />),
    isButton: true,
    buttonText: 'Consultá precios',
    buttonLinkTo: '/contact_us',
};

const servicesData = {
    services: [
        {
            image: (<StaticImage src="../../images/velocity_icon.svg" alt="Velocidad" />),
            text: 'Se puede aumentar o disminuir el ancho de banda según se requiera',
        },
        {
            image: (<CompareArrowsIcon style={{ color: colors.green, height: '73px', width: '73px' }} />),
            text: 'Monitoreamos el enlace en ambas direcciones en todo momento',
        },
        {
            image: (<StaticImage src="../../images/icono_www.svg" alt="Internet" />),
            text: 'Contamos con 5 proveedores de internet para asegurar que siempre esté disponible',
        },
        {
            image: (<LinkIcon style={{ color: colors.green, height: '73px', width: '73px' }} />),
            text: 'Enlaces simétricos y asimétricos',
        },
    ],
};

const DedicatedBandwidth = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <ServiceDataContainer servicesData={servicesData} />
        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default DedicatedBandwidth;
